import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import reducer from './../reducers'
import rootSaga from './../sagas/saga'

const configureStore = (initialState) => {
  // create saga
  const sagaMiddleware = createSagaMiddleware()

  // for redux dev tools
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

  // create store and mount saga
  const store = createStore(
    reducer,
    initialState,
    composeEnhancers(
      applyMiddleware(
        sagaMiddleware,
      ),
    ),
  )

  // start saga
  sagaMiddleware.run(rootSaga)

  return store
}
export default configureStore
