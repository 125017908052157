export const threeDigitSeparator = (str) => {
  var result = (str + '').replace(/,/g, '');
  if (result.match(/^(|-)[0-9]+$/)) {
    result = result.replace(/^[0]+([0-9]+)/g, '$1');
    result = result.replace(/([0-9]{1,3})(?=(?:[0-9]{3})+$)/g, '$1,');
  }
  return result;
}

export const formatDate = (date, format) => {
  if (!format) format = 'YYYY-MM-DD hh:mm:ss.SSS';
  format = format.replace(/YYYY/g, date.getFullYear());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  if (format.match(/S/g)) {
    var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
    var length = format.match(/S/g).length;
    for (var i = 0; i < length; i++) format = format.replace(/S/, milliSeconds.substring(i, i + 1));
  }
  return format;
}

export const queryStringParse = (text, shouldExcludeNull = true) => {
  let result = {};

  const decodedURL = decodeURI(text);

  for (const record of decodedURL.split('&')) {
    const [key_row, value] = record.split('=');
    const key_pair = key_row.split('[]');
    const decodedValue = decodeURIComponent(value);

    if (value === undefined) {
      continue;
    }
    if (decodedValue === '' && shouldExcludeNull === true) {
      continue;
    }

    // array_value
    if (key_pair.length > 1) {
      if (result[key_pair[0]]) {
        result[key_pair[0]].push(decodedValue);
      } else {
        result[key_pair[0]] = [decodedValue];
      }
    }

    // scalar_value
    if (key_pair.length === 1) {
      result[key_pair[0]] = decodedValue;
    }

  }
  return result
};

export const canonicalizeQuery = (queryList) => {

  // page=1のときは正規化URLからpageを除外する
  // 例 : xxx/site/items?page=1 -> xxx/site/items
  if ('page' in queryList && queryList.page === '1') {
    delete queryList.page;
  }

  return queryList;
};

/**
 * 受け取ったオブジェクトをクエリ文字列にシリアライズします。
 *
 * サンプル オブジェクト
 *   var query = {
 *     action: 'view',
 *     id: '123',
 *     debug: undefined,
 *     arraylist: [
 *       'val1',
 *       'val2'
 *     ],
 *     hashlist: {
 *       foo: 'foo',
 *       bar: 'bar'
 *     }
 *   }
 * サンプル シリアライズ結果
 *   ?action=view&id=123&debug&arraylist%5B%5D=val1&arraylist%5B%5D=val2&hashlist[foo]=foo&hashlist[bar]=bar
 */
export const queryStringfy = (data) => {
  let key, value, type, i, max
  let encode = encodeURIComponent
  let query = '?'
  for (key in data) {
    value = data[key]
    type = typeof (value) === 'object' && value instanceof Array ? 'array' : typeof (value)
    switch (type) {
    case 'undefined':
      // キーのみ
      query += key
      break
    case 'array':
      // 配列
      if (!value.length > 0) {
        continue
      }
      for (i = 0, max = value.length; i < max; i++) {
        query += key + '%5B%5D'
        query += '='
        query += encode(value[i])
        query += '&'
      }
      query = query.substr(0, query.length - 1)
      break
    case 'object':
      // ハッシュ
      for (i in value) {
        query += key + '%5B' + i + '%5D'
        query += '='
        query += encode(value[i])
        query += '&'
      }
      query = query.substr(0, query.length - 1)
      break
    default:
      query += key
      query += '='
      query += encode(value)
      break
    }
    query += '&'
  }
  query = query.substr(0, query.length - 1)
  return query
}

/**
 * 任意の桁で四捨五入する関数
 * @param {number} value 四捨五入する数値
 * @param {number} base どの桁で四捨五入するか
 * @return {number} 四捨五入した値
 */
export const orgRound = (value, base) => {
  return Math.round(value / base) * base;
}

/**
 * 任意の桁で切り上げする関数
 * @param {number} value 切り上げする数値
 * @param {number} base どの桁で切り上げするか
 * @return {number} 切り上げした値
 */
export const orgCeil = (value, base) => {
  return Math.ceil(value / base) * base;
}

/**
 * 任意の桁で切り捨てする関数
 * @param {number} value 切り捨てする数値
 * @param {number} base どの桁で切り捨てするか
 * @return {number} 切り捨てした値
 */
export const orgFloor = (value, base) => {
  return Math.floor(value / base) * base;
}

export const ceilWithDigit = (x, digit = 0, zoomDownBy = 1) => {
  if (typeof x !== "number") return x
  const power = 10 ** digit
  return (Math.ceil(x * power / zoomDownBy) / power).toLocaleString()
}

export const fullWidthToHalfWidthNumber = (str) => {
  return str.replace(/[０-９，]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
  })
}

export const fullWidthToHalfWidthAlphabetNumber = (str) => {
  if (!str)
    return ""
  if (typeof str !== "string")
    str = str.toString()

  return str.replace(/[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g, function(s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
  }).replace(/[-－﹣−‐⁃‑‒–—﹘―⎯⏤ーｰ─━]/g, '-')
}

export const isString = (str) => {
  return typeof str === "string"
}

export const isNumber = (number) => {
  return typeof number === "number"
}

export const priceToNumber = (price) => {
  if (price == null) return null
  return price.replace(/,/g, '')
}

export const numberToPrice = (number) => {
  if (number == null) return null

  if (Number.isInteger(number)) number = number.toString()
  number = number.replace(/,/g, '')

  if (number.match(/^(|-)[0-9]+$/)) {
    number = number.replace(/^[0]+([0-9]+)/g, '$1')
    number = number.replace(/([0-9]{1,3})(?=(?:[0-9]{3})+$)/g, '$1,')
  }
  return number
}
